<template>
  <v-card :class="cardClass">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Statistics</span>
      <v-spacer></v-spacer>
      <!-- <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <!-- <v-card-subtitle class="mb-8 mt-n5">
			<span class="font-weight-semibold text--primary me-1">Total 60.5% On time payments received.</span>
			<span>😎 this month</span>
		</v-card-subtitle> -->

    <v-card-text>
      <v-row no-gutters>
        <v-col v-for="(data, index) in statisticsData" :key="data.title" md="6" sm="6" lg="3" xl="3" class="d-flex align-center">
          <v-avatar size="44" :color="data.color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-sm mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl text-sm text-lg  text-md font-weight-semibold">{{ data.total }}{{ index > 1 ? ' / ' + data.amount : '' }}</h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCheckbook,
  mdiAccountMultiplePlusOutline,
  mdiDotsVertical,
  mdiCheckboxMarkedCircleOutline,
} from '@mdi/js'
import Util from '@/utils/axios-util.js'
import Vue from 'vue'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiAccountMultiplePlusOutline,
        mdiAccountOutline,
        mdiCheckbook,
        mdiCheckboxMarkedCircleOutline,
      },
    }
  },
  data() {
    return {
      statisticsData: [
        {
          title: 'New Customers',
          total: '-',
          icon: mdiAccountMultiplePlusOutline,
          color: 'primary',
        },
        {
          title: 'Customers',
          total: '-',
          icon: mdiAccountOutline,
          color: 'info',
        },
        {
          title: 'Un-paid Transactions',
          total: '-',
          icon: mdiCheckbook,
          color: 'warning',
          amount: '-',
        },
        {
          title: 'Successful Transactions',
          total: '-',
          icon: mdiCheckboxMarkedCircleOutline,
          color: 'success',
          amount: '-',
        },
      ],
    }
  },
  mounted() {
    let e = this

    Util.Axios.get('/Payment/dashboard/get-report1').then(function(response) {
      let data = response.data.data

      e.statisticsData[0].total = data.totalNewCustomersInLast30Days
      e.statisticsData[1].total = data.totalCustomers
      e.statisticsData[2].total = data.totalUnpaidTransactions
      e.statisticsData[2].amount = Vue.filter('format_money')(data.totalUnpaidAmount, 'INR')
      e.statisticsData[3].total = data.totalPaidTransactions
      e.statisticsData[3].amount = Vue.filter('format_money')(data.totalPaidAmount, 'INR')
    })
  },
}
</script>
