<template>
	<v-card>
		<v-data-table
			:headers="headers"
			:items="usreList"
			item-key="full_name"
			class="table-rounded"
			hide-default-footer
			disable-sort
		>
			<!-- name -->
			<template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template>
			<!-- status -->
			<template #[`item.status`]="{ item }">
				<v-chip small :color="statusColor[status[item.status]]" class="font-weight-medium">
					{{ status[item.status] }}
				</v-chip>
			</template>
			<template #[`item.action`]="{ item }">
				<v-btn color="warning" small outlined v-if="item.status == 2">
					Send reminder
					<v-icon right>{{ icons.mdiSend }}</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
	import { mdiSquareEditOutline, mdiDotsVertical, mdiSend } from '@mdi/js'
	import data from './datatable-data'

	export default {
		setup() {
			const statusColor = {
				/* eslint-disable key-spacing */
				Paid: 'success',
				Future: 'primary',
				Failed: 'error',
				/* eslint-enable key-spacing */
			}

			return {
				headers: [
					{ text: 'NAME', value: 'full_name' },
					{ text: 'AMOUNT', value: 'amount' },
					{ text: 'DATE', value: 'start_date' },
					{ text: 'TRANSACTION', value: 'transaction_no' },
					{ text: 'STATUS', value: 'status' },
					{ text: '', value: 'action' },
				],
				usreList: data,
				status: {
					1: 'Paid',
					2: 'Future',
					3: 'Failed',
					4: 'Resigned',
					5: 'Applied',
				},
				statusColor,

				// icons
				icons: {
					mdiSquareEditOutline,
					mdiDotsVertical,
					mdiSend,
				},
			}
		},
	}
</script>
