var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.usreList,"item-key":"full_name","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v(_vm._s(item.post))])])]}},{key:"item.transaction_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transaction_no)+" / "+_vm._s(item.total_transaction)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status == 2)?_c('v-btn',{attrs:{"color":"warning","small":"","outlined":""}},[_vm._v(" Send reminder "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icons.mdiSend))])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }