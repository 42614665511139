<template>
  <v-alert dismissible border="top" colored-border elevation="2" v-if="unsettled && unsettled.length">
    <v-row :loading="loading">
      <v-col cols="12">
        <v-icon>{{ icons.mdiAlertOutline }} </v-icon>&nbsp;Warning: Unsettled transaction amount
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          v-if="unsettled && unsettled.length"
          :items="unsettled"
          :loading="loading"
          class="table-rounded"
          loading-text="Loading... Please wait"
        >
          <template #[`item.payment.metaData.totalAmount.amount`]="{ item }">
            {{ item.payment.metaData.totalAmount.amount | format_money(item.payment.metaData.totalAmount.currency) }}
          </template>
          <template #[`item.email`]="{ item }">
            Unsettled transaction amount of
            <b>
              {{
                (item.payment.metaData.totalAmount.amount -
                  transactionsList
                    .filter(a => a.paymentId == item.paymentId)
                    .map(item => item.amount.amount)
                    .reduce((prev, next) => prev + next))
                  | format_money(item.payment.metaData.totalAmount.currency)
              }}</b
            >.
          </template>
          <template #[`item.payment`]="{ item }">
            {{
              transactionsList
                .filter(a => a.paymentId == item.paymentId)
                .map(item => item.amount.amount)
                .reduce((prev, next) => prev + next) | format_money(item.payment.metaData.totalAmount.currency)
            }}
          </template>
        </v-data-table>
        <!-- <p v-for="item in unsettled" :key="item.code">
				<br />
				Total Amount : {{ item.payment.metaData.totalAmount.amount }}<br />
				Total Transactions : {{ transactionsList.filter(p => p.paymentId == item.paymentId).length }}<br />
				Total of transaction amount :
				
				<hr/>
			</p> -->
        <p>
          <i>
            To remove above warnings, Please create transactions of diffrence amount for particular payment.
          </i>
        </p>
      </v-col>
    </v-row>
  </v-alert>
  <v-alert v-else dismissible border="top" colored-border elevation="2">
    <v-card :loading="loading">
      <v-card-title>
        <img :src="`${baseUrl}assets/woohoo.jpg`" height="200" style="margin-right: 20px" />
        Woo! You don't have any unsettled amount.
      </v-card-title>
    </v-card>
  </v-alert>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'
import moment from 'moment'

export default {
  setup() {
    const loading = ref(false)
    const transactionsList = reactive([])
    const unsettled = reactive([])
    const payments = reactive([])
    const headers = reactive([
      { text: 'Company', value: 'customer.companyName' },
      { text: 'Invoice No', value: 'payment.sellerInvoiceNo' },
      { text: 'Total Installments', value: 'payment.metaData.noOfInstallments' },
      { text: 'Total Amount', value: 'payment.metaData.totalAmount.amount' },
      { text: 'Total of transaction value', value: 'payment' },
      { text: 'Description', value: 'email' },
    ])

    return {
      transactionsList,
      loading,
      unsettled,
      payments,
      icons: { mdiAlertOutline },
      headers,
    }
  },
  mounted() {
    let e = this
    e.getTransactions()
  },
  methods: {
    getTransactions: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/Payment/get-paged-transactions', { params: e.filters })
        .then(function(response) {
          e.transactionsList = Object.assign([], response.data.data.items)
          //get unique payment by paymentID
          const key = 'paymentId'
          e.payments = [...new Map(e.transactionsList.map(item => [item[key], item])).values()]
          // find unsetlled payment
          e.unsettled = e.payments.filter(function(p) {
            let sumOfTransactionAmount = e.transactionsList
              .filter(a => a.paymentId == p.paymentId)
              .map(item => item.amount.amount)
              .reduce((prev, next) => prev + next)
            let unsettledAmount = p.payment.metaData.totalAmount.amount - sumOfTransactionAmount
            return unsettledAmount && unsettledAmount > 10
          })
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
  },
}
</script>
<style>
.v-alert__border--top {
  border-color: #ffab40 !important;
}
</style>
